// Composable service used to fetch data from the Java site
// todo; this could probably be refactored, but leaving it as one service for now to avoid early refactoring
import {useSecurityStore} from '@/stores/securityStore'
import {type CartLine, useCartStore} from "~/stores/cartStore";

export interface Country {
    code: string;
    name: string;
    domain: string;
    localeCode: string;
    testingEnabled: boolean;
    shippingEnabled: boolean;
    professionEnabled: boolean;
}

export interface ProductReviewSummary {
    reviewCount: number;
    averageRating: number;
}

export interface Product {
    id: number;
    sku: string;
    name: string;
    url: string;
    price: string;
    primaryImageUrl: string;
    shortDescription: string;
    longDescription: string;
    headline: string;
    reviewSummary: ProductReviewSummary;
}

export interface ProductReview {
    id: number;
    rating: number;
    subject: string;
    body: string;
    authorName: string;
    purchaseVerified: boolean;
    date: string;
}

export default function () {
    const nuxtApp = useNuxtApp();
    const securityStore = useSecurityStore();
    const cartStore = useCartStore();

    return {
        fetchCountries(shippingOnly?: boolean) {
            // fetch countries; dedupes by default (cancels requests if one is already active)
            const dataKey = shippingOnly ? 'shipping-countries' : 'countries';
            return useAsyncData(dataKey, () => nuxtApp.$siteFetch<Country[]>('/geography/countries', {
                params: {
                    format: "json",
                    type: shippingOnly ? 'shipping' : undefined
                }
            }));
        },

        fetchProductBySku(sku: string) {
            return useAsyncData(`product-data-${sku}`, () => nuxtApp.$siteFetch<Product>(`/product/sku/${sku}`));
        },

        fetchProductsBySku(skus: string[]) {
            return useAsyncData(() => nuxtApp.$siteFetch<Product[]>(`/products/skus?skus=${skus.join('&skus=')}`));
        },

        fetchProductReviewsByIds(reviewIds: number[]) {
            return useAsyncData(() => nuxtApp.$siteFetch<ProductReview[]>(`/products/reviews?reviewIds=${reviewIds.join('&reviewIds=')}`))
        },

        addToCart(productId: number, quantity: number = 1) {
            if (!securityStore.data?.csrf) {
                return Promise.reject("Invalid CSRF token/data.");
            }

            return nuxtApp.$siteFetch(`/cart/item/${productId}`, {
                method: 'POST',
                headers: {
                    [securityStore.data.csrf.headerName]: securityStore.data.csrf.token
                },
                body: {
                    quantity
                }
            }).then(_ => {
                cartStore.fetch().then(_ => {
                    const lineAdded: CartLine | undefined = cartStore.data?.lines?.find((prd: CartLine) => prd.productId === productId);
                    if (lineAdded) {
                        useGtmStore().addToCartEvent(lineAdded);
                    }
                })
            });
        }
    }
}