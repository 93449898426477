const setDynamicYieldRecommendationContext = function(path: string, DY) {
    const data: string[] = [];
    let type: string = 'OTHER';
    if (path === "/") {
        type = 'HOMEPAGE';
    } else if (path.startsWith('/products/dp')) {
        type = 'PRODUCT';
        // todo: get the sku of the item - maybe move this into the product page when we create that
        //  data.push('sku');
    } else if (path.startsWith('/products') || path.startsWith('/search')) {
        type = 'CATEGORY';
        data.push('Supplements')
    } else {
        data.push(path);
    }
    DY.recommendationContext = {type: type, data: data}
}

export { setDynamicYieldRecommendationContext }