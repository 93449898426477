export default defineNuxtPlugin((nuxtApp) => {
    const allowedDomains = [
        "thorne.com",
    ]

    const runtimeConfig = useRuntimeConfig()
    const requestDomain = useRequestURL().origin
    const defaultDomain = runtimeConfig.public.thorneSite ?? 'https://www.thorne.com'
    const hasMatch = !requestDomain.includes("nuxt") && !!allowedDomains.findLast(tld => requestDomain.toLowerCase().endsWith(tld.toLowerCase()))

    const siteFetch = $fetch.create({
        baseURL: hasMatch ? requestDomain : defaultDomain,
        headers: {
            'X-Requested-With': 'XMLHttpRequest'
        }
    });
    // Expose to useNuxtApp().$siteFetch
    return {
        provide: {
            siteFetch
        }
    }
})