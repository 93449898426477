/**
    Used to hold state regarding things that google tag manager can read. In the thorne site we refer to this as 'gtmDataLayer'.
    We fill this object up in the checkout screen, thank you page (after purchase) and on product details.
    todo: We don't have any of these pages yet - once we do we'll need to set this stuff when the page loads and
        then get the data into gtm (see components/app/header)/
 */
import type {CartLine} from "~/stores/cartStore";

export const useGtmStore = defineStore('gtmDataLayer', () => {
    const attributes = ref<object>({});

    function hasAttributes(): boolean {
        return Object.keys(attributes.value).length > 0;
    }

    function setAttribute(key: any, value: any): void {
        attributes.value[key] = value;
    }

    function clear(): void {
        for (const key in attributes.value) {
            delete attributes.value[key]
        }
    }

    function addToCartEvent(line: CartLine) {
        const dataLayer = (window as any).dataLayer
        dataLayer.push({
            event: 'addToCart',
            productSku: line.sku,
            productName: line.name,
            productPrice: line.subtotal.replace(/[^\d.-]/g, ""),
            quantity: line.quantity,
            currencyCode: 'USD'
        })
    }

    return { attributes, hasAttributes, setAttribute, clear, addToCartEvent }
})