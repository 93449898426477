export interface User {
    enabled: boolean;
    credentialsNonExpired: boolean;
    accountNonExpired: boolean;
    accountNonLocked: boolean;
    customerId: number;
    username: string;
    avatarUrl: string;
    name: string;
    firstName: string;
    lastName: string;
    initials: string;
    locale: string;
    timeZone: string;
    priceGroup: string;
    ambassador: boolean;
    professional: boolean;
    retail: boolean;
    authorities: {
        authority: string;
    }[];
    isAffiliateClient: boolean;
}

export const useUserStore = defineStore('userStore', () => {
    const { data, refresh } = useAsyncData(() => useNuxtApp().$siteFetch<User | undefined>('/account/data/me'), {
        lazy: true, // resolve after route is loaded, prevents blocking navigation
        server: false, // fetch on the client
    });

    const loggedIn = computed(() => data.value?.enabled);

    function fetch() {
        return refresh();
    }

    function $reset() {
        data.value = undefined;
    }

    return { data, loggedIn, fetch, $reset };
});