import { datadogRum } from '@datadog/browser-rum'
import { defineNuxtPlugin } from '#app'

export default defineNuxtPlugin(({
                                     $config: {
                                         public: {
                                             datadogApplicationId,
                                             datadogClientToken,
                                             datadogVersion,
                                             datadogEnv,
                                             datadogSampleRate}}}) => {

    console.log('DD RUM init:', datadogVersion, datadogEnv, datadogSampleRate)
    datadogRum.init({
        applicationId: datadogApplicationId,
        clientToken: datadogClientToken,
        site: 'datadoghq.com',
        service: 'thorne-frontend',
        version: datadogVersion ?? '1.0.0',
        env: datadogEnv ?? 'unknown',
        sessionSampleRate: datadogSampleRate ?? '25',
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
        proxy: (options) => `/dd_metrics${options.path}?${options.parameters}`,
    });
    datadogRum.startSessionReplayRecording();
})
