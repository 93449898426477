export interface Cart {
    total: string;
    subtotal: string;
    empty: boolean;
    lines: CartLine[];
}

export interface CartLine {
    productId: number
    sku: string;
    name: string;
    total: string;
    subtotal: string;
    quantity: number;
}

export interface CartResponseData {
    cart: Cart;
}

export const useCartStore = defineStore('cartStore', () => {
    const { data, refresh } = useAsyncData(() => $fetch<CartResponseData>('/cart?format=json'), {
        lazy: true, // resolve after route is loaded, prevents blocking navigation
        server: false, // fetch on the client
        transform: response => response.cart
    });

    function fetch() {
        return refresh();
    }

    function $reset() {
        data.value = null;
    }

    return { data, fetch, $reset };
});