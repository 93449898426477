import { defineNuxtRouteMiddleware } from "#app";

export default defineNuxtRouteMiddleware((to, from) => {
    const affiliateReferralCode = to.query?.affid ?? to.query?.refCode ?? to.query?.refcode;
    const affiliateReferralCodeClean: string | undefined = affiliateReferralCode?.toString().replaceAll("\r|\n", "");

    if (affiliateReferralCodeClean != null) {
        // makes a request to the java site, response contains a Set-Cookie header to populate the cookie 
        // if it already isn't set
        useNuxtApp().$siteFetch(`/customer/referrer/${affiliateReferralCodeClean}`);
    }
});