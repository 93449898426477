export interface CsrfData {
    headerName: string;
    token: string;
}

export const useSecurityStore = defineStore('securityStore', () => {
    const { data: csrf, refresh: refreshCsrf } = useFetch<CsrfData>('/csrf', {
        lazy: true,
        server: false
    });

    async function fetch() {
        await refreshCsrf();
    }

    function $reset() {
        csrf.value = null;
    }

    return { data: { csrf }, fetch, $reset };
});