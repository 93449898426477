import {useUserStore} from "@/stores/userStore";

export interface LocaleDefinition {
    language: string;
    country: Country;
    localeCode: string;
    domain?: string;
}

export const useLocaleStore = defineStore('localeStore', () => {
    const DEFAULT_LANGUAGE = "en";
    const DEFAULT_COUNTRY = "US";
    const DEFAULT_LOCALE_CODE = `${DEFAULT_LANGUAGE}-${DEFAULT_COUNTRY}`;
    const COOKIE_NAME = "locale";

    const userStore = useUserStore();

    // fetch locale cookie
    const localeCookie = useCookie(COOKIE_NAME, {
        secure: !import.meta.dev
    });

    const locales = ref<LocaleDefinition[]>([]);
    const locale = computed(() => {
        let code;
        if (localeCookie.value != null) {
            code = localeCookie.value;
        } else if (userStore?.data?.locale != null) {
            code = userStore.data.locale;
        } else {
            code = DEFAULT_LOCALE_CODE;
        }
        return getLocale(code);
    });

    async function fetchLocales() {
        try {
            const result = (await siteService().fetchCountries(true))?.data?.value;
            if (!!result) {
                locales.value = result.map((country) => {
                    return {
                        language: DEFAULT_LANGUAGE,
                        country: country,
                        localeCode: country.localeCode,
                        domain: country.domain
                    };
                });
            }
            return result;
        } catch (err) {
            console.error('Unable to fetch locales', err);
        }
    }
    
    function getLocale(localeCode: string) {
        const localeCodeFixed = localeCode?.replace("_", "-");
        return locales.value?.find(locale => localeCodeFixed == locale.localeCode);
    }

    function setLocale(newLocaleCode: string) {
        const locale = getLocale(newLocaleCode);
        if (!!locale) {
            localeCookie.value = locale.localeCode;
        }
    }

    function $reset() {
        localeCookie.value = DEFAULT_LOCALE_CODE;
    }

    return {locale, locales, getLocale, setLocale, fetchLocales, $reset};
});