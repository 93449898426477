<template>
    <NuxtLink to="#main" class="sr-only">Skip to main content</NuxtLink>
    <NuxtLayout>
        <NuxtPage id="main" role="main"/>
    </NuxtLayout>
</template>

<script setup lang="ts">
declare const window: any
import {useScriptEventPage} from '#imports'
import {joinURL, withQuery} from 'ufo'
import {setDynamicYieldRecommendationContext} from '~/vendors/dynamicYield'
import {gtmOptimize, gtmStart} from '~/vendors/google-tag-manager'
import {useGtmStore} from '~/stores/googleTagManagerStore'
import {useLocaleStore} from '~/stores/localeStore'
import type { Link } from '@unhead/vue'

const nuxtApp = useNuxtApp()
const userStore = useUserStore()
const gtmStore = useGtmStore()
const localeStore = useLocaleStore()
const runtimeConfig = useRuntimeConfig()
const route = useRoute()
const config = nuxtApp.$config
const dyReadyToLoad = ref(false)
const gtmReadyToLoad = ref(false)

const polaris = useScript(
    {
      src: '//polaris.truevaultcdn.com/static/pc/CUWUC5TUP/polaris.js',
      fetchpriority: 'high',
      defer: false,
      crossorigin: false,
      referrerpolicy: 'strict-origin-when-cross-origin',
    }
)
const dyScriptId = computed(() => {
  return localeStore.locale?.localeCode == 'en-CA' ? config.public.dynamicYieldSectionIdCA : nuxtApp.$config.public.dynamicYieldSectionIdUs;
})
const dynamicYield_dynamic = useScript(
    {
      src: `//cdn.dynamicyield.com/api/${dyScriptId.value}/api_dynamic.js`,
      fetchpriority: 'high',
      defer: false,
      crossorigin: false,
      referrerpolicy: 'strict-origin-when-cross-origin',
    },
    {
      trigger: dyReadyToLoad
    }
)
const dynamicYield_static = useScript(
    {
      src: `//cdn.dynamicyield.com/api/${dyScriptId.value}/api_static.js`,
      referrerpolicy: 'strict-origin-when-cross-origin',
    },
    {
      trigger: dyReadyToLoad
    }
)
const gtm = useScript(
    {
      src: `//www.googletagmanager.com/gtm.js?id=${config.public.googleTagManagerKey}`,
      fetchpriority: 'high',
      defer: false,
      crossorigin: false,
      referrerpolicy: 'strict-origin-when-cross-origin',
    },
    {
      trigger: gtmReadyToLoad
    }
)

polaris.onLoaded(()=>{
  window.polarisOptions = {
    GoogleAnalyticsTrackingId: config.public.googleTagManagerKey,
  }
  gtmReadyToLoad.value = true
})
gtm.onLoaded(({dataLayer}) => {
  const gtmKey = config.public.googleTagManagerKey
  const gtmOptimizeKey = config.public.googleOptimizeKey ?? ''

  let keyParam = {}
  if (gtmOptimizeKey) {
    keyParam[gtmOptimizeKey] = true
  }

  if(userStore.loggedIn) {
    dataLayer.push({
      visitor: {
        customerId: userStore.data?.customerId,
        timeZone: userStore.data?.timeZone,
        locale: userStore.data?.locale,
        priceGroup: userStore.data?.priceGroup,
        isAffiliateClient: userStore.data?.isAffiliateClient
      }
    })
  }
  if (gtmStore.hasAttributes()) {
    for (const [key, value] of Object.entries(gtmStore.attributes)) {
      dataLayer.push({key, value})
    }
  }
  gtmOptimize(window, document.documentElement, 'async-hide', 'dataLayer', 4000, keyParam);
  gtmStart(window, document, 'script', 'dataLayer', gtmKey);
})

onMounted(async () => {
  if (isDynamicYieldEnabled(config.public.dynamicYieldEnabled)) {
    window.DY = window.DY || {}
    setDynamicYieldRecommendationContext(route.fullPath, window.DY)

    dyReadyToLoad.value = true
  }
})

useScriptEventPage(({title, path}) => {
  console.log('GTM pageview logged for:', title, path)
  gtm.proxy.dataLayer.push({
    event: 'pageview',
    title,
    path
  })
})

function isDynamicYieldEnabled(flag: string | boolean): boolean {
  if (typeof flag === "boolean") {
    return flag
  } else {
    return flag.toLowerCase() === 'true'
  }
}

// fetch locales - top level await, only fetches once on the server
await localeStore.fetchLocales();

// parse locale query param, needs to be done after locales are loaded into the store/state
const localeQuery = route.query.locale;
const localeQueryValue = Array.isArray(localeQuery) ? localeQuery[0]?.toString() : localeQuery?.toString();
if (!!localeQueryValue) {
  localeStore.setLocale(localeQueryValue);
}

const getLocalizedUrl = (locale) => {
  let url = runtimeConfig.public.thorneSite;
  let path = route.path;
  if (!!locale) {
    if (!!locale.domain) {
      url = locale.domain;
    }
    else if (!!locale.localeCode) {
      path = withQuery(path, { locale: locale.localeCode });
    }
  }
  return joinURL(url, path);
}

// setup locale (canonical and href) links
const localeLinks = computed<Link[]>(() => localeStore.locales.map(val => {
  return {
    href: getLocalizedUrl(val),
    hreflang: val.localeCode,
    rel: 'alternate'
  }
}));

localeLinks.value.push({
  href: joinURL(runtimeConfig.public.thorneSite, route.path),
  hreflang: 'x-default',
  rel: 'alternate'
});

localeLinks.value.push({
  href: getLocalizedUrl(localeStore.locale), 
  rel: 'canonical' 
});

useHead({
  htmlAttrs: {
      lang: localeStore.locale?.language
  },
  link: [
    ...localeLinks.value
  ]
});
</script>
