import {config, library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faInstagram, faFacebook, faTiktok, faTwitter, faYoutube, faLinkedin} from '@fortawesome/free-brands-svg-icons'
import {
    faCapsules as fasCapsules,
    faCartPlus,
    faCircle,
    faHandshake as fasHandshake,
    faListCheck as fasListCheck,
    faMicroscope as fasMicroscope,
    faStar,
    faStarHalf
} from '@fortawesome/pro-solid-svg-icons'
import {
    faArrowLeft,
    faArrowRight,
    faArrowRightFromBracket,
    faBarcode,
    faBars,
    faBook,
    faBriefcaseMedical,
    faCapsules,
    faCartShopping,
    faCheck,
    faCheckCircle,
    faChevronDown,
    faCircleNotch,
    faCog,
    faLink,
    faListCheck,
    faHandshake,
    faMicroscope,
    faMinus,
    faMortarPestle,
    faNewspaper,
    faPaperPlane,
    faPlus,
    faSearch,
    faStore,
    faTimes,
    faUser,
    faVideo,
    faX
} from '@fortawesome/pro-regular-svg-icons'
import {
    faCapsules as falCapsules,
    faHandshake as falHandshake,
    faListCheck as falListCheck,
    faMicroscope as falMicroscope
} from '@fortawesome/pro-light-svg-icons'

config.autoAddCss = false

// FA Brand SVG Icons
// --------------------
// instagram
// facebook
// tiktok
// twitter
// youtube
// linkedin

library.add(faInstagram, faFacebook, faTiktok, faTwitter, faYoutube, faLinkedin)

// FA Solid SVG Icons
// --------------------
// circle
// star
// star-half

library.add(faCircle, faStar, faStarHalf)

// FA Solid SVG Icons
// --------------------
// cart-plus
// capsules
// circle
// handshake
// list-check
// microscope
// star
// star-half

library.add(fasCapsules, faCartPlus, faCircle, fasHandshake, fasListCheck, fasMicroscope, faStar, faStarHalf)

// FA Regular SVG Icons
// --------------------
// arrow-left
// arrow-right
// arrow-right-from-bracket
// barcode
// bars
// book
// briefcase-medical
// capsules
// cart-shopping
// check
// check-circle
// chevron-down
// cog
// handshake
// link
// list-check
// microscope
// minus
// mortar-pestle
// newspaper
// paper-plane
// plus
// search
// store
// times
// user
// video
// x

library.add(faArrowLeft, faArrowRight, faArrowRightFromBracket, faBarcode, faBars, faBook, faBriefcaseMedical, faCapsules, faCartShopping, faCheck, faCheckCircle, faChevronDown, faCircleNotch, faCog, faHandshake, faLink, faListCheck, faMortarPestle, faMicroscope, faNewspaper, faPaperPlane, faSearch, faStore, faTimes, faUser, faVideo, faX, faMinus, faPlus)

// FA Light SVG Icons
// --------------------
// capsules
// handshake
// microscope
// list-check
library.add(falCapsules, falHandshake, falMicroscope, falListCheck)

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.component('font-awesome-icon', FontAwesomeIcon);
});