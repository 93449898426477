export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.directive('click-outside', {
        beforeMount(el, binding) {
            // create event listener on dom to listen for clicks outside of this element
            el.clickOutsideEvent = event => {
                if (el == event.target || el.contains(event.target)) return;

                binding.value(); // call binding value for directive
            }

            document.addEventListener('click', el.clickOutsideEvent);
        },
        unmounted(el) {
            document.removeEventListener('click', el.clickOutsideEvent);
        },
    })
});